import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonPage,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { menu, personRemove, reload, personCircle, play } from "ionicons/icons";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import "./Home.css";

class Player {
  name = "";
  score = 0;
  zeroCounter = 0;
  constructor(name: string, score = 0, zeroCounter = 0) {
    this.name = name;
    this.score = score;
    this.zeroCounter = zeroCounter;
  }
}

const Home: React.FC<RouteComponentProps> = (props) => {
  const version = "v1.0";
  const [playing, setPlaying] = useState<Player[]>([]);
  const [currentPlayer, setCurrentPlayer] = useState<number>(0);
  const [updatedScore, setUpdatedScore] = useState({
    score: 0,
    time: new Date(),
  });
  const [dismissPlayer, setDissmissPlayer] = useState<Player>();
  const [gameState, setGameState] = useState("init"); // init, ended

  const updateScore = (points: number) => {
    setUpdatedScore({ score: points, time: new Date() });
  };

  // score, next player
  useEffect(() => {
    setPlaying((e) => {
      return e.map((item, index) => {
        if (index === currentPlayer) {
          let newScore = item.score + updatedScore.score;
          if (newScore > 50) {
            newScore = 25;
          }
          let newZeroCounter = 0;
          if (updatedScore.score === 0) {
            newZeroCounter = item.zeroCounter + 1;
          }
          return { ...item, score: newScore, zeroCounter: newZeroCounter };
        }
        return { ...item };
      });
    });
  }, [updatedScore]);

  // next player
  useEffect(() => {
    setCurrentPlayer((n) => {
      let next = n;
      console.log("whos next: " + n);
      do {
        next = (next + 1) % playing.length;
        console.log("calculating next: " + next);
      } while (playing.length > 1 && playing[next]?.zeroCounter === 3);
      console.log("nextyyy: " + next);
      return next;
    });
  }, [updatedScore]);

  // player overhang
  useEffect(() => {
    setCurrentPlayer((n) => n % playing.length);
  }, [playing]);

  // last player standing
  useEffect(() => {
    let activePlayers = playing.filter((p) => p.zeroCounter !== 3);
    console.log("player count: " + activePlayers.length);
    if (activePlayers.length === 1) {
      console.log("only 1 player left");
      setGameState(
        "Congratulations 🥳 " + activePlayers[0].name + " is the winner!"
      );
    }
  }, [playing]);

  // dismiss player, game end
  useEffect(() => {
    console.log(
      "gameEnded: " +
        gameState +
        ", n: " +
        currentPlayer +
        ", zero: " +
        playing[currentPlayer]?.zeroCounter
    );
    if (playing && playing.length > 0) {
      let winner = playing.find((i) => i.score === 50);
      if (winner) {
        setGameState("Congratulations 🥳 " + winner.name + " is the winner!");
      }
    }
  }, [playing]);

  useEffect(() => {
    if (!dismissPlayer) {
      return;
    }
    console.log("Dismissing player: " + dismissPlayer.name);
  }, [dismissPlayer]);

  useIonViewDidEnter(() => {
    reset();
  });

  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  function reset() {
    setGameState("init");
    setCurrentPlayer(0);
    setPlaying([
      new Player("Kiki"),
      new Player("Marc"),
      new Player("Pink"),
      new Player("Mickey"),
      new Player("Carie"),
      new Player("Henryk"),
    ]);
  }

  function deleteUser() {
    setPlaying((e) => {
      return e.filter((item, index) => index !== currentPlayer);
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>The Mölkky Companyon</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding-start ion-padding-end">
        <IonCard>
          {playing.map((player, index) => (
            <IonItem
              key={index}
              color={
                index === currentPlayer
                  ? "secondary"
                  : player.zeroCounter === 3
                  ? "danger"
                  : ""
              }
            >
              <IonIcon
                icon={personCircle}
                slot="start"
                color={
                  player.zeroCounter === 1
                    ? "warning"
                    : player.zeroCounter === 2
                    ? "danger"
                    : ""
                }
              />
              <IonCardContent>
                <h1>{player.name}</h1>
              </IonCardContent>
              <IonCardContent slot="end">
                <h1>{player.score}</h1>
              </IonCardContent>
            </IonItem>
          ))}
        </IonCard>

        <IonGrid>
          <IonRow>
            {range(0, 12, 1).map((i, index) => (
              <IonCol size="3" key={index}>
                <IonButton
                  expand="block"
                  onClick={() => {
                    updateScore(i);
                  }}
                >
                  {i}
                </IonButton>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton>
            <IonIcon icon={menu}></IonIcon>
          </IonFabButton>
          <IonFabList side="start">
            <IonFabButton onClick={() => deleteUser()}>
              <IonIcon icon={personRemove} />
            </IonFabButton>
            <IonFabButton onClick={() => reset()}>
              <IonIcon icon={reload} />
            </IonFabButton>
          </IonFabList>
        </IonFab>

        <IonToast
          isOpen={gameState !== "init"}
          onDidDismiss={() => {
            reset();
          }}
          message={gameState}
          duration={2000}
          position="middle"
        />

        <IonToast
          isOpen={dismissPlayer !== undefined}
          onDidDismiss={() => {
            setDissmissPlayer(undefined);
          }}
          message={"3 zeros in a row: " + dismissPlayer?.name + ", you're out!"}
          duration={2000}
          position="middle"
        />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonTitle size="small">
            made with ❤️ by moin•ahoi ({version})
          </IonTitle>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Home;
